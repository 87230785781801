import React, { useEffect, useState } from "react";
import "../../css/Navbar.css";
import "../../css/Slider.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Sliders = ({ darkMode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const durations = [3000, 11000, 3000, 3000]; // Durations for each slide (ms)
  const slides = ["image1", "video", "image2", "image3"]; // Slide types

  useEffect(() => {
    const changeSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const interval = setInterval(changeSlide, durations[currentIndex]);

    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    // Fade in elements as the user scrolls
    gsap.fromTo(
      ".scroll-animation",
      { y: 50, opacity: 0 }, // Starting position below with opacity 0
      {
        y: 0,
        opacity: 1, // Final position
        duration: 1.5,
        ease: "power2.out",
        scrollTrigger: {
          trigger: ".scroll-animation",
          start: "top 80%", // Animation starts when the section is 80% visible
        },
      }
    );

    // Animate the circular gradient with subtle movement
    gsap.to(".border-animation", {
      y: "-2%", // Slight downward movement
      x: "-2%", // Slight leftward movement
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      duration: 6,
    });
  }, []);

  return (
    <div className={darkMode ? "dark-mode" : ""}>
      {/* Image slider */}
      <div className="slider">
        <img
          className={`slide ${currentIndex === 0 ? "active" : ""}`}
          src="./images/fire_new.jpg"
          alt="Gas Suppression System"
        />

        <video
          className={`slide ${currentIndex === 1 ? "active" : ""}`}
          width="100%"
          height="auto"
          autoPlay
          loop
          muted
        >
          <source src="./video/water_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <img
          className={`slide ${currentIndex === 2 ? "active" : ""}`}
          src="./images/firefiter.jpg"
          alt="Firefighter"
        />

        <img
          className={`slide ${currentIndex === 3 ? "active" : ""}`}
          src="./images/R.jpeg"
          alt="Firefighter"
        />
      </div>

      {/* Content with circle animation */}
      <div className="flex flex-col lg:flex-row mt-16 lg:mt-36 lg:ml-36 scroll-animation relative items-center lg:items-start">
        {/* Circle with shadow and gradient */}
        <div className="border-animation absolute z-[-1]"></div>

        {/* Image section */}
        <div className="relative mb-10 lg:mb-0 lg:ml-[10vh]">
          <img
            src="./images/Things Engineers Spotted During Structural Inspections.jpeg"
            className="w-[70vw] h-[40vh] lg:w-[40vh] lg:h-[50vh] rounded-lg shadow-lg"
            alt="Engineers' Inspection"
          />
        </div>

        {/* Text content */}
        <div className="text-center lg:text-left lg:ml-[10vh]">
          <h4 className="text-[4vw] lg:text-[4vh] font-medium mt-10 lg:mt-20">
            Delivering excellence
            <span className="text-[#F92E2A]"> in every</span>
            <br />
            projects, every time.
          </h4>

          <Link to="/about">
            <div className="relative inline-block group w-full max-w-[240px] mt-10">
              <p className="relative m-0 h-[54px] leading-[54px] bg- text- overflow-hidden box-border group-hover:text-white transition-all ease-out">
                <span className="absolute w-full h-full border border-[#ece8e1] left-0 box-border"></span>
                <span className="absolute h-full bg-[#ff4655] w-0 left-[-5%] transform -skew-x-[10deg] transition-all duration-300 ease-out z-10 group-hover:w-full"></span>
                <span className="absolute z-20 w-full h-full left-0 top-0 text-center">About us →</span>
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sliders;

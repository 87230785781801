import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const AccessControl = ({ darkMode }) => {
  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className="flex flex-col lg:flex-row h-screen">
        {/* Left Section: Background with overlay text */}
        <div 
          className="relative bg-cover bg-center w-full lg:w-1/2 h-1/2 lg:h-full"
          style={{ backgroundImage: `url('./images/ac_page.jpg')` }} // Corrected syntax for image path
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-center text-white p-8 border border-white">
              <h1 className="text-sm lg:text-lg font-bold">
                Comprehensive Access Control Solutions for Enhanced Security
              </h1>
            </div>
          </div>
        </div>
        
        {/* Right Section: List of Services */}
        <div className="flex flex-col justify-center w-full lg:w-1/2 p-8 lg:p-16 overflow-y-hidden">
          <div className="space-y-6 text-sm">

            {/* Service 1 */}
            <div>
              <h2 className="font-bold text-sm mt-52">Custom Access Control System Design: Tailored to Your Needs.</h2>
              <p className="mt-2">
                We offer custom access control system designs that cater to your specific security requirements. Our experts will assess your property and recommend the best solutions to protect your assets.
              </p>
            </div>

            {/* Service 2 */}
            <div>
              <h2 className="font-bold text-sm">Installation of Advanced Access Control Systems.</h2>
              <p className="mt-2">
                Our professional installation services cover a range of access control technologies, including key card systems, biometric scanners, and mobile access solutions to ensure secure entry points.
              </p>
            </div>

            {/* Service 3 */}
            <div>
              <h2 className="font-bold text-sm">Comprehensive Access Management Solutions.</h2>
              <p className="mt-2">
                Manage and monitor access with our comprehensive software solutions that allow you to control who enters your premises, track access history, and receive alerts for unauthorized access attempts.
              </p>
            </div>

            {/* Service 4 */}
            <div>
              <h2 className="font-bold text-sm">Access Control Maintenance & Support Services.</h2>
              <p className="mt-2">
                Ensure your access control systems function optimally with our maintenance services. We provide regular checks, updates, and troubleshooting to keep your security solutions up-to-date and reliable.
              </p>
            </div>

            {/* Service 5 */}
            <div>
              <h2 className="font-bold text-sm">Integration with Existing Security Systems.</h2>
              <p className="mt-2">
                We offer seamless integration of access control systems with your existing security infrastructure, including CCTV, alarm systems, and monitoring services, for a comprehensive security solution.
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default AccessControl;

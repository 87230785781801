import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Cctv = ({ darkMode }) => {
  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className="flex flex-col lg:flex-row h-screen">
        {/* Left Section: Background with overlay text */}
        <div 
          className="relative bg-cover bg-center w-full lg:w-1/2 h-1/2 lg:h-full"
          style={{ backgroundImage: `url('./images/cctv_page.jpg')` }} // replace with your image path
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-center text-white p-8 border border-white">
              <h1 className="text-sm lg:text-lg font-bold">
                Comprehensive CCTV Solutions for Enhanced Security
              </h1>
            </div>
          </div>
        </div>
        
        {/* Right Section: List of Services */}
        <div className="flex flex-col justify-center w-full lg:w-1/2 p-8 lg:p-16 overflow-y-auto">
          <div className="space-y-6 text-sm">

            {/* Service 1 */}
            <div>
              <h2 className="font-bold text-sm mt-52">Expert CCTV Installation Services: Safeguard Your Property.</h2>
              <p className="mt-2">
                We provide professional CCTV installation services to enhance the security of your home or business. Our skilled technicians will assess your premises and design a tailored CCTV system that meets your specific security needs.
              </p>
            </div>

            {/* Service 2 */}
            <div>
              <h2 className="font-bold text-sm">CCTV Maintenance & Support: Keeping Your System Running Smoothly.</h2>
              <p className="mt-2">
                Our CCTV maintenance services ensure that your surveillance systems are always operational. We offer regular check-ups, troubleshooting, and repairs to prevent downtime and maintain optimal performance.
              </p>
            </div>

            {/* Service 3 */}
            <div>
              <h2 className="font-bold text-sm">Advanced CCTV Technology: Stay Ahead with Smart Features.</h2>
              <p className="mt-2">
                We offer the latest in CCTV technology, including HD and 4K cameras, night vision, motion detection, and remote viewing capabilities. Enhance your security with advanced features that provide peace of mind.
              </p>
            </div>

            {/* Service 4 */}
            <div>
              <h2 className="font-bold text-sm">CCTV System Upgrades: Enhance Your Security Setup.</h2>
              <p className="mt-2">
                If you have an outdated CCTV system, we provide upgrade solutions that include modern cameras and improved storage options. Ensure that your security setup meets current standards and requirements.
              </p>
            </div>

            {/* Service 5 */}
            <div>
              <h2 className="font-bold text-sm">24/7 Monitoring Services: Ensure Constant Vigilance.</h2>
              <p className="mt-2">
                Our 24/7 monitoring services allow you to keep an eye on your property at all times. Our trained staff will monitor your CCTV feeds, providing immediate alerts in case of suspicious activity.
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default Cctv;

import React, { useState, useEffect } from "react";

const ProjectCard = ({ project, darkMode }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading for 3 seconds
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // Change this value for longer or shorter loading time

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out ${
        darkMode ? "border-gray-700" : "border-gray-300"
      }`}
    >
      {!isLoaded ? (
        <div
          className={`flex flex-col justify-center items-center w-full h-48 bg-gray-200 animate-pulse`}
        >
          <div className="h-24 w-3/4 bg-gray-300 mb-4 rounded" />
          <div className="h-6 w-1/2 bg-gray-300 rounded" />
        </div>
      ) : (
        <>
          <div className="w-full">
            {/* Project Information (Text Above) */}
            <div className="p-4 absolute">
              <h2 className="text-xl font-semibold text-black">{project.name}</h2>
              <p
                className={`text-sm ${
                  darkMode ? "text-black" : "text-gray-600"
                }`}
              >
                {project.location}
              </p>
            </div>

            {/* Project Image (Below Text) */}
            <div className="w-full h-auto">
              <img
                src={project.imageUrl}
                alt={project.name}
                className="w-full max-h-56 object-cover"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectCard;

import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import '../css/Loader.css';

const LoadingScreen = ({ onLoaded }) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setTimeout(() => {
          onLoaded(); // Notify parent to stop loading
        }, 100); // Small delay before signaling
      },
    });

    // Animate the text to fade in first
    tl.to(textRef.current, { opacity: 1, duration: 1, ease: 'power2.out' }) // Fade in text
      .to(textRef.current, { opacity: 0, duration: 1, ease: 'power2.in' }, '+=1') // Fade out text after 1s
      .to(containerRef.current, { y: '-100%', duration: 1.5, ease: 'power2.out' }); // Move background up

  }, [onLoaded]);

  return (
    <div className="loader-container" ref={containerRef}>
      {/* SIDDHARTH MEP PVT LTD text */}
      <div className="text" ref={textRef} style={{ opacity: 0 }}>
        SIDDHARTH MEP PVT LTD
      </div>
    </div>
  );
};

export default LoadingScreen;

import React from "react";
import Navbar from "../pages/Navbar/Navbar";
import Footer from "../pages/Footer/Footer";
import { Link } from "react-router-dom";

const Plumbing = ({ darkMode }) => {
  const offerings = [
    ["Plumbing System", "Sanitary Installation"],
    ["Water Supply Services", "Water Pumps"],
    ["Drainage Installations", "Fire Alarm System"],
    ["Fire Pumps & Controller", "Fire Extinguishing Equipment"],
    ["Automatic Fire Extinguisher – FCO", "FM200 System"],
    ["Air Conditioning Installation", "Mechanical Ventilation Installation"],
  ];

  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className={`px-6 py-10 font-sans ${darkMode ? "bg-[#181818] text-white" : "bg-white text-black"}`}>
        <h2 className="text-[#ff5c00] font-bold text-2xl mb-6">
          Our Offerings
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {offerings.map((offering, index) => (
            <React.Fragment key={index}>
              <div className={`text-lg border-b ${darkMode ? "border-gray-700" : "border-gray-300"} py-2`}>
                {offering[0]}
              </div>
              <div className={`text-lg border-b ${darkMode ? "border-gray-700" : "border-gray-300"} py-2`}>
                {offering[1]}
              </div>
            </React.Fragment>
          ))}
        </div>
        <Link to="/businesses"><p className=" mt-16 text-[#ff5c00] cursor-pointer w-10">Back</p></Link>
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default Plumbing;

import React from 'react';
import '../../css/Contact.css'; // Import the CSS file

const Contact = () => {
  return (
    <div className='contact-container'>
      <div className='border-animation_two'></div>
      <div className='tilted-section'>
        <div className='tilted-content'>
          <p className='text-lg sm:text-xl lg:text-2xl'>
            For more information please email:
          </p>
          <a href="mailto:Siddharth@gmail.com">
            <div className="relative inline-block group w-full max-w-[240px] mt-5">
              <p className="relative m-0 h-[54px] leading-[54px] bg-[#1E3A8A] text-[#ece8e1] overflow-hidden box-border group-hover:text-white transition-all ease-out">
                <span className="absolute w-full h-full border border-[#ece8e1] left-0 box-border"></span>
                <span className="absolute h-full bg-[#ff4655] w-0 left-[-5%] transform -skew-x-[10deg] transition-all duration-300 ease-out z-10 group-hover:w-full"></span>
                <span className="absolute z-20 w-full h-full left-0 top-0 text-center">info.siddharthmep@gmail.com</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react';
import { Link } from 'react-router-dom';

const ProjectsSection = ({ darkMode }) => {
  const projects = [
    {
      title: 'Businesses',
      description: 'We offer Electrical Work, Fire Fighting Systems, Plumbing, Access Control, and CCTV installations for enhanced safety and efficiency.',
      image: '/images/pexels-pixabay-265087.jpg',
      alt: 'Businesses',
      link: '/businesses', 
    },
    {
      title: 'Project',
      description: 'We partner with leading clients like TCS, Hindustan Lever, Lodha, Honeywell (Flipkart), and Dosti Group, delivering exceptional service and innovative solutions.',
      image: '/images/work.jpg',
      alt: 'Project',
      link: '/project', 
    },
  ];

  return (
    <section className="p-8 lg:flex lg:justify-between lg:items-start space-y-12 lg:space-y-0 lg:space-x-8 lg:mt-44">
      {projects.map((project, index) => (
        <div
          key={index}
          className={`lg:w-1/2 ${darkMode ? 'bg-gray-800' : 'bg-'} shadow-lg rounded-lg overflow-hidden`}
        >
          <img src={project.image} alt={project.alt} className="w-full h-64 object-cover" />
          <div className="p-6">
            <h3 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-gray-100' : 'text-'}`}>{project.title}</h3>
            <p className={`text-gray-600 ${darkMode ? 'dark:text-gray-300' : ''}`}>{project.description}</p>
            <Link to={project.link}>
              <div className="relative inline-block group w-full max-w-[240px] mt-4">
                <p className="relative m-0 h-[54px] leading-[54px] overflow-hidden box-border group-hover:text-white transition-all ease-out">
                  <span className="absolute w-full h-full border border-[#ece8e1] left-0 box-border"></span>
                  <span className="absolute h-full bg-[#ff4655] w-0 left-[-5%] transform -skew-x-[10deg] transition-all duration-300 ease-out z-10 group-hover:w-full"></span>
                  <span className="absolute z-20 w-full h-full left-0 top-0 text-center">LEARN MORE</span>
                </p>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
};

export default ProjectsSection;

import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Businesses = ({ darkMode }) => { 
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const slides = [
    {
      image: "/images/e_page.jpg",
      title: "Electrical Work",
      description: "We specialize in all types of electrical installations and services for both residential and commercial projects.",
    },
    {
      image: "/images/Types of Plumbing System.jpg",
      title: "Plumbing Systems",
      description: "Our expert plumbing services ensure efficient and reliable water systems, from repairs to complete installations.",
    },
    {
      image: "/images/cctv_page.jpg",
      title: "CCTV",
      description: "Keep your property safe with our state-of-the-art CCTV installations and surveillance systems.",
    },
    {
      image: "/images/ac_page.jpg",
      title: "Access Control",
      description: "Our access control systems provide top-tier security, ensuring only authorized personnel enter your premises.",
    },
    {
      image: "/images/fire_page_img.jpeg",
      title: "Fire Fighting System",
      description: "We provide high-quality fire protection systems to safeguard lives and properties from potential fire hazards.",
    }
  ];

  const intervalRef = useRef(null);

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      clearInterval(intervalRef.current);
    } else {
      intervalRef.current = setInterval(goToNextSlide, 3000); // Change slide every 3 seconds
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      intervalRef.current = setInterval(goToNextSlide, 3000);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPlaying]);

  return (
    <div className={`relative w-full h-screen flex items-center justify-center overflow-hidden ${darkMode ? '' : 'bg-white'}`}>
      <div className={`flex flex-col md:flex-row items-center justify-between max-w-7xl mx-auto px-4 md:px-8 lg:px-16 ${darkMode ? 'text-gray-300' : 'text-gray-900'}`}>
        {/* Slide Image */}
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
          <img
            src={slides[currentSlide].image}
            alt={slides[currentSlide].title}
            className="w-full h-auto object-cover rounded-md"
          />
        </div>

        {/* Slide Text */}
        <div className="w-full md:w-1/2 px-4 md:px-8 lg:px-12 text-center md:text-left">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
            {slides[currentSlide].title}
          </h1>
          <p className="text-base sm:text-lg md:text-xl mb-4">
            {slides[currentSlide].description}
          </p>
        </div>
      </div>

      {/* Slider Controls */}
      <div className="absolute bottom-14 flex space-x-4 lg:ml-[70%]">
        <button
          onClick={goToPrevSlide}
          className="bg-white p-2 text-black "
        >
          ←
        </button>
        <span className="text-gray-400 pt-2">{currentSlide + 1}/{slides.length}</span>
        <button
          onClick={goToNextSlide}
          className="bg-white p-2 text-black"
        >
          →
        </button>
      </div>

      {/* Play/Pause Button */}
      <div className="absolute bottom-14 left-8">
        <button
          onClick={togglePlayPause}
          className="bg-white p-2 rounded-full lg:ml-36"
        >
          {isPlaying ? (
            <img src="/images/pause.png" className="w-8 sm:w-12" />
          ) : (
            <img src="/images/play-button.png" className="w-8 sm:w-12" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Businesses;

import { useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import gsap from "gsap";
import "../../css/Navbar.css"; // Existing CSS file for custom styles

const Navbar = ({ darkMode }) => {
  const textRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Mobile menu state

  // Sticky navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // GSAP animation for logo text on hover
  useEffect(() => {
    const text = textRef.current;
    const tl = gsap.timeline({ paused: true });

    tl.to(text, {
      x: 30, // Move the text to the right
      opacity: 0,
      duration: 0.5,
      ease: "power1.inOut",
    })
      .set(text, { x: -30 }) // Reset to the left off-screen
      .to(text, {
        x: 0, // Bring it back to its original position
        opacity: 1,
        duration: 0.5,
        ease: "power1.inOut",
      });

    text.addEventListener("mouseenter", () => tl.play());
    text.addEventListener("mouseleave", () => tl.reverse());

    return () => {
      text.removeEventListener("mouseenter", () => tl.play());
      text.removeEventListener("mouseleave", () => tl.reverse());
    };
  }, []);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Effect to manage scrolling
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMobileMenuOpen]);

  return (
    <nav
      className={`navbar ${isSticky ? "sticky" : ""} ${
        darkMode ? "dark-navbar" : ""
      }`}
    >
      <div className="container mx-auto flex items-center justify-between h-24">
        <NavLink to="/" className="logo h-24 flex">
          <img
            src="./images/logo_cs-transformed.png"
            className="w-18 h-16 pt-6 cursor-pointer ml-5"
            alt="Company Logo"
          />
          <h4
            ref={textRef}
            className="text-[#F92E2A] font-medium pl-7 pt-8 cursor-pointer"
          >
            S I D D H A R T H M E P P V T
          </h4>
        </NavLink>

        {/* Desktop Menu */}
        <div className="hidden lg:flex gap-10 mr-7 font-medium z-50">
          {["about","client", "businesses", "project", "contact"].map((item) => (
            <NavLink
              key={item}
              to={`/${item}`}
              className={({ isActive }) =>
                `nav-item cursor-pointer ${
                  darkMode ? "text-white" : "text-black"
                } ${isActive ? "active" : ""}`
              }
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </NavLink>
          ))}
        </div>

        {/* Mobile Hamburger Menu Icon */}
        <div className="lg:hidden flex items-center pr-6">
          <button
            className="text-black dark:text-white focus:outline-none"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <span className="text-3xl text-[#F92E2A]">✕</span> // Close icon
            ) : (
              <span className="text-3xl text-[#F92E2A]">☰</span> // Hamburger icon
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-white dark:bg-[white] absolute w-full top-24 left-0 text-center z-40">
          <ul className="space-y-6 py-6">
            {["about","client", "businesses", "project", "contact"].map((item) => (
              <li key={item}>
                <NavLink
                  to={`/${item}`}
                  onClick={toggleMobileMenu}
                  className={({ isActive }) =>
                    `text-black dark:text-white hover:text-[#F92E2A] ${
                      isActive ? "active" : ""
                    }`
                  }
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from "react";
import Navbar from "../pages/Navbar/Navbar";
import Footer from "../pages/Footer/Footer";

const ContactPage = ({ darkMode }) => {
  return (
    <>
      <div
        className={
          darkMode
            ? "dark-mode bg-white text-black"
            : "light-mode bg-white text-gray-800"
        }
      >
        <Navbar darkMode={darkMode} />

        {/* Hero Image with Contact Us Text */}
        <div className="relative w-full h-80">
          <img
            src="/images/earth_about_us.webp"
            alt="About Us Earth Image"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-4xl lg:text-5xl font-bold text-white">
              Contact Us
            </h1>
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="container mx-auto px-6 py-12 lg:py-20 lg:px-24">
          {/* Corporate Office Information */}
          <div
            className={
              darkMode
                ? "bg-black text-white p-8 rounded-lg shadow-lg flex flex-wrap lg:flex-nowrap justify-between items-start gap-6"
                : "bg-white p-8 rounded-lg shadow-lg flex flex-wrap lg:flex-nowrap justify-between items-start gap-6"
            }
          >
            {/* Company Information */}
            <div className="w-full lg:w-1/2 lg:pr-8">
              {/* Adjusted spacing on larger screens */}
              <h4 className="text-xl font-semibold text-orange-500 mb-4">
                Corporate Office
              </h4>
              <h2 className="text-2xl font-bold mb-4">
                Siddharth MEP Pvt. Ltd.
              </h2>
              <p className="mb-2">
                Srushti Hills, 303/A-18,  
                <br/>
                Dafodil CHS LTD, (West)
                <br />
                Ambernath, Maharashtra - 421503
              </p>
              <p className="mb-2">
                <strong>Phone:</strong>  +91 8097675222
              </p>
              <p>
                <strong>Email:</strong> info.siddharthmep@gmail.com
              </p>
            </div>

            {/* Office Image */}
            <div className="w-full lg:w-1/2 lg:pl-8">
              {/* Added padding on larger screens */}
              <img
                src="/images/Screenshot (174).png"
                alt=" Siddharth MVP Pvt. Ltd. Office"
                className="rounded-lg shadow-lg w-full lg:h-[40vh] h-auto object-cover"
              />
            </div>
          </div>

          {/* Google Map Section */}
          <div className="mt-12">
            <h4 className="text-xl font-semibold text-orange-500 mb-4">
              Our Location
            </h4>
            <div className="w-full h-96 rounded-lg overflow-hidden shadow-lg">
              <iframe
                title="Google Map - Siddharthnagar, Kansai Section"
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.689925935594!2d73.16259907447395!3d19.205546851763104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be795007e819807%3A0x3a3c44f3203b7db4!2sSiddharthnagar%2C%20Kansai%20Section%2C%20Ambernath%2C%20Maharashtra%20421501!5e0!3m2!1sen!2sin!4v1696765484397!5m2!1sen!2sin`}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer darkMode={darkMode} />
      </div>
    </>
  );
};

export default ContactPage;

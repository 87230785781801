import React from "react";
import { useNavigate } from "react-router-dom";

const WhyWorkWithUs = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <section className="flex flex-col lg:flex-row items-center justify-center lg:justify-between lg:px-20 px-6 py-10 lg:py-16">
      {/* Image Section */}
      <div className="w-full lg:w-1/3">
        <img
          src="/images/Screenshot (174).png"
          alt="Building"
          className="w-full h-auto object-cover"
        />
      </div>

      {/* Content Section */}
      <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:ml-16 text-center lg:text-left">
        <h2 className="text-3xl lg:text-5xl font-bold">Why Work with Us?</h2>
        <p className="text-gray-500 mt-4 mb-6">
          At Siddharth MEP Pvt Ltd, we pride ourselves on delivering
          high-quality solutions that meet the diverse needs of our clients. Our
          commitment to excellence ensures that every project is executed with
          precision and care.
        </p>

        {/* Buttons Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 text-black">
          {[
            { name: "Electrical Work", path: "/electrical-work" },
            { name: "Fire Fighting System", path: "/fire" },
            { name: "Plumbing Systems", path: "/plumbing-system" },
            { name: "Access Control", path: "/access-control" },
            { name: "CCTV", path: "/cctv" },
          ].map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-gray-100 p-4 rounded-lg shadow-md hover:bg-[#F94D12] transition duration-300 cursor-pointer"
              onClick={() => handleNavigation(item.path)} // Navigate to the corresponding page
            >
              <span className="font-medium">{item.name}</span>
              <span className="text-green-600 hover:text-white transition duration-300">
                &rarr;
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyWorkWithUs;

import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ darkMode }) => {
  return (
    <footer
      className={`py-10 ${
        darkMode ? "bg-[#181818] text-white" : "bg-white text-black"
      }`}
    >
      <div className="container mx-auto px-6 md:px-12 lg:px-16">
        <div className="flex flex-wrap justify-between gap-y-8">
          {/* Popular Searches */}
          <div className="w-full sm:w-1/2 md:w-1/4">
            <Link to="/about">
              <h4 className="text-lg font-semibold lg:w-28">About Us</h4>
            </Link>
            <div className="cursor-pointer mt-4 lg:w-32">
              {[
                "Assets",
                "Who We Are",
                "What We Do",
                "Strengths",
                "Client and Partner",
              ].map((item, idx) => (
                <p key={idx} className="pt-3 hover:underline">
                  {item}
                </p>
              ))}
            </div>
          </div>

          {/* Most Used */}
          <div className="w-full sm:w-1/2 md:w-1/4">
            <Link to="/businesses">
              <h4 className="text-lg font-semibold">Businesses</h4>
            </Link>
            <div className="cursor-pointer mt-4 lg:w-32">
              {[
                "Electrical",
                "Plumbing",
                "Security Systems",
                "Maintenance Services",
              ].map((item, idx) => (
                <p key={idx} className="pt-3 hover:underline">
                  {item}
                </p>
              ))}
            </div>
          </div>

          {/* Additional Section */}
          <div className="w-full sm:w-1/2 md:w-1/4">
            <Link to="/contact">
              <h4 className="text-lg font-semibold">Contact</h4>
            </Link>
            <div className="cursor-pointer mt-4 lg:w-32">
              {["+91 8097675222", "info.siddharthmep@gmail.com"].map(
                (item, idx) => (
                  <p key={idx} className="pt-3 hover:underline">
                    {item}
                  </p>
                )
              )}
            </div>
          </div>

          {/* Social Media */}
          <div className="w-full sm:w-1/2 md:w-1/4">
            <h4 className="text-lg font-semibold">Follow Us</h4>
            <div className="flex mt-4 space-x-4">
              {[
                { src: "whatsapp.png", url: "https://wa.me/message/KRLWOJO4JWO3J1" }, // Replace with your WhatsApp link
                { src: "twitter.png", url: "https://twitter.com/your-profile" }, // Replace with your Twitter link
              ].map((item, idx) => (
                <a key={idx} href={item.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={`/images/${item.src}`}
                    className="h-8 cursor-pointer"
                    alt={`Social Media ${idx}`}
                  />
                </a>
              ))}
            </div>
          </div>

          {/* Horizontal Divider */}
          <div
            className={`h-[1px] w-full mt-8 ${
              darkMode ? "bg-white" : "bg-[#F93A37]"
            }`}
          />

          {/* Footer Bottom */}
          <div className="mt-5 text-center">
            © Copyright 2024. All rights reserved. SIDDHARTH MEP PVT LTD.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import "./App.css";
import { useState, useRef, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import Navbar from "./components/pages/Navbar/Navbar";
import Sliders from "./components/pages/Slider/Sliders";
import Businesses from "./components/pages/Businesses/Businesses";
import Contact from "./components/pages/Contacts/Contact";
import Footer from "./components/pages/Footer/Footer";
import About from "./components/pages/About/About";
import BusinessesPage from "./components/BusinessesPage/BusinessesPage";
import Electrical from "./components/BusinessesPage/Electrical";
import SecuritySystems from "./components/BusinessesPage/SecuritySystems";
import Plumbing from "./components/BusinessesPage/Plumbing";
import OtherServices from "./components/BusinessesPage/OtherServices";
import ProjectPage from "./components/ProjectsPage/ProjectPage";
import ContactPage from "./components/ContactPage/ContactPage";
import ScrollToTop from "../src/ScrollToTop"; 
import LoadingScreen from "./components/Loader/Loader"; // Import the LoadingScreen component
import Fire from "./components/pages/Works/Fire";
import Cctv from "./components/pages/Works/Cctv";
import AccessControl from "./components/pages/Works/AccessControl";
import ElectricalWork from "./components/pages/Works/ElectricalWork";
import PlumbingSystem from "./components/pages/Works/PlumbingSystem";
import WhyWorkWithUs from "./components/pages/WhyWorkWithUs/WhyWorkWithUs";
import ProjectsSection from "./components/pages/ProjectsSection/ProjectsSection";
import Client from "./components/Clients/Client";

function App() {
  const [darkMode, setDarkMode] = useState(true); // Start in dark mode
  const [loading, setLoading] = useState(true); // State for loading screen
  const loadingBarRef = useRef(null);
  const location = useLocation();

  // Track page views on route changes using gtag.js
  useEffect(() => {
    const handlePageView = (url) => {
      window.gtag('config', 'YOUR_GA4_MEASUREMENT_ID', {
        page_path: url,
      });
    };
    
    handlePageView(window.location.pathname + window.location.search);
  }, [location]);

  // Toggle between dark and light modes
  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  // Trigger loading bar on route change
  useEffect(() => {
    const currentLoadingBar = loadingBarRef.current;
    currentLoadingBar?.continuousStart();

    // Simulate loading time (adjust as necessary)
    setTimeout(() => {
      currentLoadingBar?.complete();
      setLoading(false); // Hide loading screen after loading
    }, 500); // Adjust the timeout duration

  }, [location]); 

  return (
    <>
      <LoadingBar color="#EA580C" ref={loadingBarRef} />
      
      {/* Toggle Button */}
      <button onClick={toggleDarkMode} className="toggle-btn mt-[80vh] z-50">
        {darkMode ? (
          <img src="/images/brightness.png" className="w-4" alt="Light Mode" />
        ) : (
          <img src="/images/night-mode.png" className="w-4" alt="Dark Mode" />
        )}
      </button>

      <ScrollToTop />

      {loading ? ( // Show loading screen if loading is true
        <LoadingScreen onLoaded={() => setLoading(false)} />
      ) : (
        <div className={darkMode ? "dark-mode" : "light-mode"}>
          <Routes>
            {/* Home Page Components */}
            <Route path="/" element={
              <>
                <Navbar darkMode={darkMode} />
                <Sliders />
                <ProjectsSection />
                <WhyWorkWithUs />
                <Businesses darkMode={darkMode}/>
                <Contact />
                <Footer darkMode={darkMode} />
              </>
            } />

            {/* Other Pages */}
            <Route path="/about" element={<About darkMode={darkMode} />} />
            <Route path="/businesses" element={<BusinessesPage darkMode={darkMode} />} />
            <Route path="/client" element={<Client darkMode={darkMode} />} />
            <Route path="/electrical" element={<Electrical darkMode={darkMode} />} />
            <Route path="/plumbing" element={<Plumbing darkMode={darkMode} />} />
            <Route path="/securitysystems" element={<SecuritySystems darkMode={darkMode} />} />
            <Route path="/otherservices" element={<OtherServices darkMode={darkMode} />} />
            <Route path="/project" element={<ProjectPage darkMode={darkMode} />} />
            <Route path="/contact" element={<ContactPage darkMode={darkMode} />} />
            <Route path="/fire" element={<Fire darkMode={darkMode} />} />
            <Route path="/cctv" element={<Cctv darkMode={darkMode} />} />
            <Route path="/access-control" element={<AccessControl darkMode={darkMode} />} />
            <Route path="/electrical-work" element={<ElectricalWork darkMode={darkMode} />} />
            <Route path="/plumbing-system" element={<PlumbingSystem darkMode={darkMode} />} />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;

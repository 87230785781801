import React from "react";
import Navbar from "../pages/Navbar/Navbar";
import Slider from "react-slick";
import Footer from "../pages/Footer/Footer";
import { Link } from "react-router-dom";

const BusinessesPage = ({ darkMode }) => {
  const offerings = [
    {
      number: "01",
      title: "Electrical Installation & Maintenance Services",
      description: "Charging up the industry’s deep circuits",
      link: "/electrical",
    },
    {
      number: "02",
      title: "Plumbing Installation & Maintenance Services",
      description: "Constructing airy, breathable structures",
      link: "/Plumbing",
    },
    {
      number: "03",
      title: "Security Systems Maintenance Services",
      description: "Ensuring safe and sound sanitation",
      link: "/SecuritySystems",
    },
    {
      number: "04",
      title: "Other Services",
      description: "Risk-proofing businesses and buildings against fire",
      link: "/OtherServices",
    },
  ];

  const projects = [
    {
      image: "/images/Honeywell_img.jpg",
      title: "Honeywell (Flipkart)",
    },
    {
      image: "/images/colgate-palmolive-office-768x576.jpg",
      title: "Colgate",
    },
    {
      image: "/images/dosti_group.jpeg",
      title: "Dosti Group",
    },
    {
      image: "/images/lodha-palava.jpg",
      title: "Lodha Palava",
    },
    {
      image: "/images/hl-b.gif",
      title: "unilever",
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides on larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Larger screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium screens
        settings: {
          slidesToShow: 2, // Show 2 slides for medium screens
        },
      },
      {
        breakpoint: 480, // Mobile screens
        settings: {
          slidesToShow: 1, // Show 1 slide for mobile
        },
      },
    ],
  };

  return (
    <div className={`${darkMode ? "bg-[#181818] text-white" : "bg-white text-black"}`}>
      <Navbar darkMode={darkMode} />

      {/* Offerings Section */}
      <div className="offerings-section py-12">
        <h2 className={`text-4xl font-bold mb-10 text-center ${darkMode ? "text-orange-500" : "text-orange-600"}`}>
          Our Offerings
        </h2>
        <div className="offerings-container max-w-4xl mx-auto overflow-hidden">
          {offerings.map((offering, index) => (
            <div
              key={index}
              className={`offering-item flex items-center justify-between py-8 border-b ${darkMode ? "border-gray-700" : "border-gray-300"}`}
            >
              <div className="offering-info ml-4 md:ml-8">
                <h3 className={`text-5xl font-bold ${darkMode ? "text-orange-500" : "text-orange-600"}`}>
                  {offering.number}
                </h3>
                <h4 className="text-2xl font-semibold mt-2">{offering.title}</h4>
                <p className={`text-lg mt-1 ${darkMode ? "text-gray-400" : "text-gray-500"}`}>
                  {offering.description}
                </p>
              </div>
              <div className="offering-arrow">
                <Link to={offering.link}>
                  <button
                    className={`rounded-full border-2 p-4 transition duration-300 ${darkMode ? "border-gray-600 hover:bg-orange-500 hover:text-white" : "border-gray-200 hover:bg-orange-500 hover:text-white"}`}
                  >
                    <span className="text-xl">→</span>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Landmark Projects Section */}
      <div className="landmark-projects-section py-12">
        <h2 className={`text-4xl font-bold mb-10 ${darkMode ? "text-orange-500" : "text-orange-600"} lg:ml-48`}>
          Landmark Projects
        </h2>
        <div className="overflow-hidden">
          <Slider {...sliderSettings} className="max-w-6xl mx-auto">
            {projects.map((project, index) => (
              <div key={index} className="project-item flex justify-center p-2">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full object-cover rounded-lg"
                  style={{ maxHeight: '200px', height: '100%' }} // Set max height to maintain aspect ratio
                />
                <h3 className="text-center mt-2 text-xl">{project.title}</h3>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Contact Section */}
      <div className={`contact-section py-12 mt-28 ${darkMode ? "bg-[#2C2C2C]" : "bg-gray-50"}`}>
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="text-left mb-4 md:mb-0">
            <h2 className="text-1xl font-semibold">Find out how we can help your business</h2>
          </div>
          <div className="text-right flex items-center">
            <div className="mr-4">
              <p className={`${darkMode ? "text-gray-400" : "text-gray-600"} mb-1`}>Write to us at</p>
              <p className="text-lg">info.siddharthmep@gmail.com</p>
            </div>
            <a href="mailto:info.siddharthmep@gmail.com">
              <img
                src="/images/gmail.png"
                alt="Logo"
                className="w-12 h-12 object-contain"
              />
            </a>
          </div>
        </div>
      </div>

      <Footer darkMode={darkMode} />
    </div>
  );
};

export default BusinessesPage;



import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer"

const Fire = ({ darkMode }) => {
  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className="flex flex-col lg:flex-row h-screen">
      {/* Left Section: Background with overlay text */}
      <div 
        className="relative bg-cover bg-center w-full lg:w-1/2 h-1/2 lg:h-full"
        style={{ backgroundImage: `url('./images/fire_page_img.jpeg')` }} // replace with your image path
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-center text-white p-8 border border-white">
            <h1 className="text-sm lg:text-lg font-bold">
              Service Certification & Validation of Fire Extinguisher in India
            </h1>
          </div>
        </div>
      </div>
      
      {/* Right Section: List of Services */}
      <div className="flex flex-col justify-center w-full lg:w-1/2 p-8 lg:p-16 overflow-y-hidden">
        <div className="space-y-6 text-sm">

          {/* Service 1 */}
          <div>
            <h2 className="font-bold text-sm">Guaranteed Fire Protection in India: Your One-Stop Shop for Fire Extinguisher Services.</h2>
            <p className="mt-2">
              We provide comprehensive fire extinguisher services in India, including inspection, servicing, maintenance, recharging, refilling, and certification. Our expert technicians ensure your fire extinguishers (DCP, CO2, water, foam) comply with local regulations, keeping you fire-ready and legally compliant.
            </p>
          </div>

          {/* Service 2 */}
          <div>
            <h2 className="font-bold text-sm">Fast and Reliable Fire Extinguisher Refilling in India for All Types.</h2>
            <p className="mt-2">
              We offer prompt and dependable fire extinguisher refilling services across India for a wide range of extinguisher types, including Dry Chemical Powder (DCP), Carbon Dioxide (CO2), water, and foam extinguishers. Additionally, we service Gas Based Fire Suppression Systems, guaranteeing your entire fire safety system functions optimally.
            </p>
          </div>

          {/* Service 3 */}
          <div>
            <h2 className="font-bold text-sm">Hydrostatic Testing for Fire Extinguishers in India by Certified Professionals.</h2>
            <p className="mt-2">
              Our team of highly qualified professionals conducts crucial hydrostatic testing for fire extinguishers in India. This mandatory test verifies the structural integrity of your fire extinguisher, ensuring it can withstand the pressure required for effective fire suppression.
            </p>
          </div>

          {/* Service 4 */}
          <div>
            <h2 className="font-bold text-sm">Flexible Fire Extinguisher Rental Solutions in India – Short-Term or Long-Term.</h2>
            <p className="mt-2">
              Don’t have the upfront cost for permanent fire extinguishers? We provide convenient fire extinguisher rental solutions in India for both short-term projects and long-term needs. This temporary fire protection option is ideal for construction sites, events, renovations, or any situation requiring interim fire safety measures.
            </p>
          </div>

          {/* Service 5 */}
          <div>
            <h2 className="font-bold text-sm">Certified Fire Extinguishers in India: Sales, Refills & Certification.</h2>
            <p className="mt-2">
              Looking for top-quality fire extinguishers in India? We supply a comprehensive range of certified DCP and CO2 fire extinguishers. We can also refill your existing compliant fire extinguishers, guaranteeing continued functionality and adherence to fire safety regulations.
            </p>
          </div>

        </div>
      </div>
    </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default Fire;

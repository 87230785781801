import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const ElectricalWork = ({ darkMode }) => {
  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className="flex flex-col lg:flex-row h-screen">
        {/* Left Section: Background with overlay text */}
        <div 
          className="relative bg-cover bg-center w-full lg:w-1/2 h-1/2 lg:h-full"
          style={{ backgroundImage: `url('./images/e_page.jpg')` }} // replace with your image path
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-center text-white p-8 border border-white">
              <h1 className="text-sm lg:text-lg font-bold">
                Expert Electrical Work for Safety and Efficiency
              </h1>
            </div>
          </div>
        </div>
        
        {/* Right Section: List of Services */}
        <div className="flex flex-col justify-center w-full lg:w-1/2 p-8 lg:p-16 overflow-y-hidden">
          <div className="space-y-6 text-sm">

            {/* Service 1 */}
            <div>
              <h2 className="font-bold text-sm mt-52">Comprehensive Electrical Installations: Residential & Commercial.</h2>
              <p className="mt-2">
                We provide complete electrical installation services for both residential and commercial properties. Our licensed electricians ensure all installations meet safety standards and regulations.
              </p>
            </div>

            {/* Service 2 */}
            <div>
              <h2 className="font-bold text-sm">Electrical Repairs & Troubleshooting: Fast and Reliable Solutions.</h2>
              <p className="mt-2">
                Our team offers quick and effective electrical repair services to resolve any issues you may face. From faulty wiring to breaker problems, we diagnose and fix issues efficiently.
              </p>
            </div>

            {/* Service 3 */}
            <div>
              <h2 className="font-bold text-sm">Lighting Design & Installation: Enhance Your Space.</h2>
              <p className="mt-2">
                We specialize in lighting design and installation to enhance the aesthetics and functionality of your space. Whether for home or business, we create lighting solutions that fit your needs.
              </p>
            </div>

            {/* Service 4 */}
            <div>
              <h2 className="font-bold text-sm">Electrical Maintenance Services: Ensure Optimal Performance.</h2>
              <p className="mt-2">
                Regular electrical maintenance is crucial for safety and efficiency. We offer maintenance services that include inspections, testing, and updates to keep your systems running smoothly.
              </p>
            </div>

            {/* Service 5 */}
            <div>
              <h2 className="font-bold text-sm">Energy Efficiency Solutions: Reduce Your Costs.</h2>
              <p className="mt-2">
                Our energy efficiency solutions help you reduce electricity costs while maintaining comfort and functionality. We provide assessments and recommend upgrades to improve energy performance.
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default ElectricalWork;

import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const PlumbingSystem = ({ darkMode }) => {
  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className="flex flex-col lg:flex-row h-screen">
        {/* Left Section: Background with overlay text */}
        <div 
          className="relative bg-cover bg-center w-full lg:w-1/2 h-1/2 lg:h-full"
          style={{ backgroundImage: `url('./images/what-is-a-plumbing-system.jpeg')` }} // replace with your image path
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-center text-white p-8 border border-white">
              <h1 className="text-sm lg:text-lg font-bold">
                Reliable Plumbing Systems for Your Home and Business
              </h1>
            </div>
          </div>
        </div>
        
        {/* Right Section: List of Services */}
        <div className="flex flex-col justify-center w-full lg:w-1/2 p-8 lg:p-16 overflow-y-hidden">
          <div className="space-y-6 text-sm">

            {/* Service 1 */}
            <div>
              <h2 className="font-bold text-sm mt-52">Complete Plumbing Installations: New Builds & Renovations.</h2>
              <p className="mt-2">
                We provide comprehensive plumbing installation services for new constructions and renovations. Our experienced plumbers ensure all systems are installed correctly and efficiently.
              </p>
            </div>

            {/* Service 2 */}
            <div>
              <h2 className="font-bold text-sm">Emergency Plumbing Services: 24/7 Response.</h2>
              <p className="mt-2">
                Our emergency plumbing services are available 24/7 to address urgent issues such as leaks, clogs, and burst pipes. We respond quickly to minimize damage and restore functionality.
              </p>
            </div>

            {/* Service 3 */}
            <div>
              <h2 className="font-bold text-sm">Drain Cleaning & Unclogging: Keep Your Pipes Flowing Smoothly.</h2>
              <p className="mt-2">
                We offer professional drain cleaning services to remove blockages and prevent future clogs. Our methods ensure your plumbing system remains clear and functioning efficiently.
              </p>
            </div>

            {/* Service 4 */}
            <div>
              <h2 className="font-bold text-sm">Regular Plumbing Maintenance: Prevent Problems Before They Start.</h2>
              <p className="mt-2">
                Our maintenance services include regular inspections and servicing of your plumbing systems to catch issues early and ensure optimal performance. Prevent costly repairs down the line.
              </p>
            </div>

            {/* Service 5 */}
            <div>
              <h2 className="font-bold text-sm">Water Heater Installation & Repair: Ensure Hot Water Availability.</h2>
              <p className="mt-2">
                We specialize in the installation and repair of water heaters, ensuring you have a reliable supply of hot water. Our technicians can help you choose the right system for your needs.
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default PlumbingSystem;

import React, { useState } from "react";
import { projectsData } from "../projectsData/projectsData";
import Navbar from "../pages/Navbar/Navbar";
import Footer from "../pages/Footer/Footer";
import ProjectCard from "./ProjectCard"; // Import the new component

const ProjectFilter = ({ darkMode }) => {
  const [selectedCompany, setSelectedCompany] = useState("All");

  const handleFilterChange = (e) => {
    setSelectedCompany(e.target.value);
  };

  const filteredProjects =
    selectedCompany === "All"
      ? projectsData.flatMap((company) => company.projects)
      : projectsData.find((company) => company.company === selectedCompany)
          .projects;

  return (
    <>
      <Navbar darkMode={darkMode} />
      <div className={`p-6 ${darkMode ? "bg-black text-white" : "bg-gray-50 text-gray-800"}`}>
        <h1 className="text-4xl font-bold text-center mb-8">Our Projects</h1>

        {/* Filter Section */}
        <div className="flex justify-center mb-8">
          <select
            className={`p-3 border ${darkMode ? "border-gray-700 bg-black text-white" : "border-gray-300 bg-white text-gray-700"} rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            value={selectedCompany}
            onChange={handleFilterChange}
          >
            <option value="All">All Verticals</option>
            {projectsData.map((company, index) => (
              <option key={index} value={company.company}>
                {company.company}
              </option>
            ))}
          </select>
        </div>

        {/* Grid View of Projects */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProjects.map((project, index) => (
            <ProjectCard key={index} project={project} darkMode={darkMode} />
          ))}
        </div>
      </div>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default ProjectFilter;
